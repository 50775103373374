<template>
  <div class="apply-banner">
    <div class="apply-name">
      <h2>{{ post.service_name }}</h2>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Applyheader',
  props: ['post']
}
</script>
<style scoped src="../../assets/css/apply.css"></style>
